import React, { useState, useEffect } from 'react';
import axios from 'axios';

// import Drawer from '@material-ui/core/Drawer';
// import Button from '@material-ui/core/Button';
// import List from '@material-ui/core/List';
// import Divider from '@material-ui/core/Divider';
// import ListItem from '@material-ui/core/ListItem';
// import ListItemIcon from '@material-ui/core/ListItemIcon';
// import ListItemText from '@material-ui/core/ListItemText';
import InputBase from '@material-ui/core/InputBase';
// import InboxIcon from '@material-ui/icons/MoveToInbox';
// import MailIcon from '@material-ui/icons/Mail';
// import { InputBase } from '@material-ui/core';
// import SearchIcon from '@material-ui/icons/Search';

//import ImageDetail from './image-details'
//import Spring from './SpringModal'
import PrimarySearchAppBar from './PrimarySearchAppBar';
import { makeStyles, fade } from '@material-ui/core/styles';

import ImageRow from './imageRow';
import useWindowSize from '../hooks/useWindowSize'
import useDebounce from '../hooks/useDebounce'

const apiURL = 'https://api.photos.art.style'
//const apiURL = 'http://localhost:8699'

const useStyles = makeStyles(theme => ({
  list: {
    width: 250,
  },
  fullList: {
    width: 'auto',
  },
  search: {
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: fade(theme.palette.common.white, 0.15),
    '&:hover': {
      backgroundColor: fade(theme.palette.common.white, 0.25),
    },
    marginRight: theme.spacing(2),
    marginLeft: 0,
    width: '100%',
    // [theme.breakpoints.up('sm')]: {
    //   marginLeft: theme.spacing(3),
    //   width: 'auto',
    // },
  },
  searchIcon: {
    width: theme.spacing(7),
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  inputRoot: {
    color: 'inherit',
  },
  inputInput: {
    padding: theme.spacing(1, 1, 1, 7),
    transition: theme.transitions.create('width'),
    width: '100%',
    [theme.breakpoints.up('md')]: {
      width: 200,
    },
  },
}));

function ImageContainer() {
  const classes = useStyles();
  const [data, setData] = useState([]);
  const [dataRows, setDataRows] = useState([]);
  const [loaddata, setLoaddata] = useState(0);
  const [search, setSearch] = useState('');
  const [model, setModel] = useState();
  const [windowWidth, windowHeight] = useWindowSize();
  const debouncedWindowWidth = useDebounce(windowWidth, 100);
  //   useEffect(async () => {
  //     const result = await axios(
  //       'http://localhost:8699',
  //     );
  //     console.log(result.data.hits.hits)
  //     setData(result.data.hits);
  //   }, []);


  const [state, setState] = React.useState({
    top: false,
    left: false,
    bottom: false,
    right: false,
  });

  const splitToRows = (items, rowWidth, targetRowHeight) => {
    const maxAspectX = rowWidth / targetRowHeight;
    const result = [];
    let AspectX = 0;
    let itemsRow = [];
    console.log(rowWidth)
    items.map((item) => {
      if (item._source.aspect + AspectX < maxAspectX) {
        //console.log(item)
        AspectX = AspectX + item._source.aspect;
        itemsRow.push(item._source);
      }
      else {
        if (AspectX > 0) result.push({ totalAspectX: AspectX, targetRowHeight, itemsRow });
        AspectX = item._source.aspect;
        itemsRow = [];
        itemsRow.push(item._source);
      }

    })
    result.push({ totalAspectX: AspectX, targetRowHeight, itemsRow });
    console.log(result)
    return (result)
  }

  const toggleDrawer = (side, open) => event => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }

    setState({ ...state, [side]: open });
  };

  useEffect(() => {
    {
      window.scroll({
        top: 0,
        left: 0,
        // behavior: 'smooth',
      });
    }
    return () => {
      //cleanup
    };
  }, [data]);

  useEffect(() => {
    async function fetchData() {
      // You can await here
      const response = await axios(
        apiURL,
      );
      setData(response.data.hits.hits);
    }
    fetchData();
    setModel(null)
  }, [loaddata]); // Or [] if effect doesn't need props or state

  useEffect(() => {
    async function fetchData() {
      // You can await here
      const response = await axios(
        `${apiURL}/search/${JSON.stringify(search)}`,
      );
      setData(response.data.hits.hits);
      console.log('search', search)
      if (search.modelID) {
        console.log(search.modelID)
        let responseM = await axios.get(
          `${apiURL}/api/models`, { params: { data: search.modelID } }
        )
        // let responseM = await axios.get(
        //   `${apiURL}/api/models`, { params: { data: '21637508' } }
        // )
        setModel(responseM.data[0]._source)

      } else setModel(null)
        ;
    }
    if (search !== '') fetchData();

    return () => {
      //cleanup
    };
  }, [search]);

  useEffect(() => {
    setDataRows(splitToRows(data, windowWidth, 400))
    return () => {
      //cleanup
    };
  }, [data, debouncedWindowWidth])

  useEffect(() => {
    console.log('models:', model)
    return () => {
      //cleanup
    };
  }, [model]);

  function handleSearch(newValue) {
    console.log('inimage-container', newValue)
    setModel(null)
    setSearch(newValue)
  }
  function handleToggleDrawer() {
    setState({ ...state, ['left']: true })
  }

  const handleOnChangeRating = (e) => {
    console.log("Rating is:", e.target.value)
    axios.post(
      `${apiURL}/api/models`,
      { data: { ...model, rating: e.target.value } }
    );
  }

  return (
    <React.Fragment>
      <PrimarySearchAppBar position="sticky" handleSearch={handleSearch} toggleDrawer={handleToggleDrawer}>

        <button className='btn' onClick={() => setLoaddata(loaddata + 1)}>Next</button>
        <span> Window size: {windowWidth} x {windowHeight}   </span> {model ? <InputBase
          placeholder="enter Rating"
          defaultValue={model.rating}
          classes={{
            root: classes.inputRoot,
            input: classes.inputInput,
          }}
          style={{ fontSize: "24px", fontWeight: 600 }}
          inputProps={{ 'aria-label': 'search' }}
          onChange={handleOnChangeRating}
        /> : null}
        <div>
          {/*JSON.stringify(data)*/}
          {data ?
            dataRows.map((row, index) => {
              if ((windowWidth / row.totalAspectX) / 1.5 > row.targetRowHeight)
                return (
                  <div key={index} style={{ height: row.targetRowHeight, backgroundColor: 'beige', display: 'flex', width: '100%', margin: "2px" }}>
                    <ImageRow rows={row.itemsRow} handleSearch={handleSearch} imageHeight={row.targetRowHeight}></ImageRow>
                  </div>
                )
              else return (
                <div key={index} style={{ height: windowWidth / row.totalAspectX, backgroundColor: 'beige', display: 'flex', width: '100%', padding: "2px" }}>
                  <ImageRow rows={row.itemsRow} handleSearch={handleSearch} imageHeight={windowWidth / row.totalAspectX} ></ImageRow>
                </div>)
            })
            : null}
        </div>
        <button className='btn' onClick={() => {

          setLoaddata(loaddata + 1);

        }}>Next</button>
      </PrimarySearchAppBar>
    </React.Fragment>
  );
}

export default ImageContainer;