import React, { useState, useEffect } from 'react';

import { makeStyles } from '@material-ui/core/styles';
// import Icon from '@material-ui/core/Icon';
// import IconButton from '@material-ui/core/IconButton';
// import DeleteIcon from '@material-ui/icons/Delete';
// import AddShoppingCartIcon from '@material-ui/icons/AddShoppingCart';
// import PhotoCamera from '@material-ui/icons/PhotoCamera';

import Fab from '@material-ui/core/Fab';
import AddIcon from '@material-ui/icons/Add';
import RemoveIcon from '@material-ui/icons/Remove';


import NavigationIcon from '@material-ui/icons/Navigation';
import axios from 'axios';
import useHover from '../hooks/use-hover';
import MaxWidthDialog from "./MaxWidthDialog";

const useStyles = makeStyles(theme => ({
    button: {
        margin: theme.spacing(1),
        color: 'white'
    },
    input: {
        display: 'none',
    },
    container: {}

}));


const ImageDetail = (props) => {
    const classes = useStyles();
    // Declare a new state variable, which we'll call "count"
    const [count, setCount] = useState(props.allin.countclick);

    const path = `https://photos.art.style/photos/preview/${props.src}/${props.id}.jpg`
    const [hoverRef, isHovered] = useHover();

    const onError = (e) => console.log(e.target.src)

    useEffect(() => {
        async function postImage(image_data) {
            // You can await here

            await axios.post(
                'https://api.photos.art.style/api/images',
                { data: image_data }
            );



        }
        //console.log(count, props.allin.countclick)
        if (count !== props.allin.countclick) postImage({ ...props.allin, countclick: count });

    }, [count, props.allin]); // Or [] if effect doesn't need props or state

    const handleViweModel = (event) => {
        props.handleSearch({ modelID: props.allin.models[0].id })
    }

    const handleViweContributor = (event) => {
        props.handleSearch({ contributorID: props.allin.contributor.id })
    }


    //console.log(props.)
    return (

        <div className={'container'} style={{ height: props.imageHeight, width: props.imageHeight * props.allin.aspect, }} ref={hoverRef}  >
            <img style={{ height: '100%', padding: '2px' }} src={path} alt="" key={props.src.id} onError={onError} />
            {isHovered ? <div className='controls' >

                <Fab color="primary" aria-label="Add" className={classes.fab} onClick={handleViweContributor}>
                    Creator
                </Fab>
                <Fab color="primary" aria-label="Add" className={classes.fab} onClick={handleViweModel}>
                    Model
                </Fab>
                <Fab color="primary" aria-label="Add" className={classes.fab} onClick={() => {
                    if (count) setCount(count - 1);
                    else setCount(-1)
                }}>
                    <RemoveIcon />
                </Fab>
                <Fab color="primary" aria-label="Add" className={classes.fab} onClick={() => {
                    if (count) setCount(count + 1);
                    else setCount(1)
                }}>
                    <AddIcon />
                </Fab>
                <MaxWidthDialog src={path} allin={props.allin}></MaxWidthDialog>


                <div style={{ width: '100%' }}>
                    {count > 0 ? <Fab variant="extended" aria-label="Up" className={classes.fab}>
                        <NavigationIcon className={classes.extendedIcon} />
                        Rating is {count}
                    </Fab> : count < 0 ? <Fab variant="extended" aria-label="Down" className={classes.fab}>
                        <RemoveIcon className={classes.extendedIcon} />
                        Rating is {count}
                    </Fab> : null}
                </div>

                <div style={{ backgroundColor: 'rgba(52, 52, 52, 0.8)', width: '100%', margin: 0 }}><p style={{ textShadow: '0px 1px 3px black', padding: '10px', margin: 0 }}>{props.allin.description} ({props.allin.models[0].id})</p></div>
                {/*<Fab disabled aria-label="Delete" className={classes.fab}>
                    <DeleteIcon />
                </Fab>
                <IconButton color="primary" className={classes.button} aria-label="Add to shopping cart">
                    <AddShoppingCartIcon fontSize="large" className='shadow' />
                </IconButton>
                <input accept="image/*" className={classes.input} id="icon-button-file" type="file" />
                <label htmlFor="icon-button-file">
                    <IconButton
                        color="primary"
                        className={classes.button}
                        aria-label="Upload picture"
                        component="span"
                        style={{ boxShadow: '0px 5px 10px rgba(0,0,0,0.8)', textShadow: '1px 1px 2px pink' }}

                    >
                        <PhotoCamera fontSize="large" />
                    </IconButton>
                </label>
            <p className={classes.fab}>You clicked {count} times</p>*/}
            </div> : null
            }
        </div>


    );
}
export default ImageDetail;