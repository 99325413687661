import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import Switch from '@material-ui/core/Switch';
import Fab from '@material-ui/core/Fab';
import OpenInNewIcon from '@material-ui/icons/OpenInNew';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';

import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';

//import Avatar from '@material-ui/core/Avatar';
import Chip from '@material-ui/core/Chip';
//import FaceIcon from '@material-ui/icons/Face';
import DoneIcon from '@material-ui/icons/Done';

import JSONPretty from 'react-json-pretty';


const useStyles = makeStyles(theme => ({
  form: {
    display: 'flex',
    flexDirection: 'column',
    margin: 'auto',
    width: 'fit-content',
  },
  formControl: {
    marginTop: theme.spacing(2),
    minWidth: 120,
  },
  formControlLabel: {
    marginTop: theme.spacing(1),
  },
  dialog: {
    margin: 0,
    width: '100%'
  },
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  },
  chip: {
    margin: '2px'
  }
}));

export default function MaxWidthDialog(props) {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const [fullWidth, setFullWidth] = React.useState(true);
  const [maxWidth, setMaxWidth] = React.useState('sm');
  const [keywords, setKeywords] = useState(props.allin.keywords);
  const [toggleKey, setToggleKey] = React.useState('none');
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('xl'));
  console.log(props.allin)


  useEffect(() => {
    console.info('togglekey', toggleKey)
    setKeywords(keywords.filter((item) => item !== toggleKey))
    return () => {

    };
  }, [toggleKey]);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleMaxWidthChange = event => {
    setMaxWidth(event.target.value);
  };

  const handleFullWidthChange = event => {
    setFullWidth(event.target.checked);
  };

  // const handleDelete = (e) => {
  //   console.info('You clicked the delete icon. ', e);
  // };

  // const handleClick = () => {
  //   console.info('You clicked the Chip.');
  // };


  return (
    <React.Fragment >
      <Fab color="primary" aria-label="Up" className={classes.fab} onClick={handleClickOpen}>
        <OpenInNewIcon className={classes.extendedIcon} />
      </Fab>
      <Dialog
        fullWidth={fullWidth}
        maxWidth={maxWidth}
        className={classes.dialog}
        fullScreen={fullScreen}
        open={open}
        onClose={handleClose}
        aria-labelledby="max-width-dialog-title"
      >
        <DialogTitle id="max-width-dialog-title">Optional sizes</DialogTitle>
        <DialogContent>
          <DialogContentText>
            {props.allin.description}
          </DialogContentText>
          <div className={classes.root}>
            <Grid container spacing={3}>
              <Grid item md>
                <img style={{ maxWidth: "100%" }} src={props.allin.assets.preview_1500.url} alt="" key={props.key} />

                <form className={classes.form} noValidate>
                  <FormControl className={classes.formControl}>
                    <InputLabel htmlFor="max-width">maxWidth</InputLabel>
                    <Select
                      autoFocus
                      value={maxWidth}
                      onChange={handleMaxWidthChange}
                      inputProps={{
                        name: 'max-width',
                        id: 'max-width',
                      }}
                    >
                      <MenuItem value={false}>false</MenuItem>
                      <MenuItem value="xs">xs</MenuItem>
                      <MenuItem value="sm">sm</MenuItem>
                      <MenuItem value="md">md</MenuItem>
                      <MenuItem value="lg">lg</MenuItem>
                      <MenuItem value="xl">xl</MenuItem>
                    </Select>
                  </FormControl>
                  <FormControlLabel
                    className={classes.formControlLabel}
                    control={
                      <Switch color="primary" checked={fullWidth} onChange={handleFullWidthChange} value="fullWidth" />
                    }
                    label="Full width"
                  />
                </form>
              </Grid>
              <Grid item md={4}>
                <JSONPretty id="json-pretty" data={JSON.stringify({ ...props.allin, assets: [] }, null, '\t')}></JSONPretty>
              </Grid>
              <Grid item md>
                <Paper className={classes.paper}>xs</Paper>
                {keywords.map((value, index) =>
                  <Chip color="primary" label={value} key={index} onDelete={() => setToggleKey(value)} deleteIcon={<DoneIcon />} variant="outlined" size="medium" className={classes.chip} />)}
              </Grid>
            </Grid>
          </div>



        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}