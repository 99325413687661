import React from 'react';
import ImageDetail from './image-details'


const ImageRow = ({ rows, handleSearch, imageHeight,totalAspectX }) => {

  // console.log(props.rows)
  //console.log(window.innerWidth, document.documentElement.clientWidth)
  return (
    <React.Fragment >
      {rows.map((item) => {
        //console.log(item.aspect, rows.totalAspectX, `${item.aspect/totalAspectX}%`)
        return (<ImageDetail key={item.id} src={item.contributor.id} id={item.id} allin={item} position={item.sourceQuery.position} handleSearch={handleSearch} imageHeight={imageHeight} totalAspectX={`${(item.aspect/totalAspectX)*100}%`}  />)

      })}
    </React.Fragment>
  );
}

export default ImageRow;
