import React from 'react';
//import logo from './logo.svg';
import './App.css';
//import ImageDetail from './components/image-details';
import ImageContainer from './components/image-container'
import PersistentDrawerLeft from './components/PersistentDrawer';

function App() {
  return (



    <ImageContainer></ImageContainer>

  );
}

export default App;
